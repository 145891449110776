






import React, { useState, useEffect } from 'react';
import { BrowserRouter, Route, Routes, Link } from 'react-router-dom';
import Mailbox from './components/mailbox';
import Email from './components/email';
import axios from 'axios';
import PrivacyPolicy from './components/privatepolicy';
import Contact from './components/contact';
import AboutUs from './components/about';
import './App.css';

function App() {
  const storedEmail = JSON.parse(localStorage.getItem('generatedEmail')) || null;
  const [selectedMail, setSelectedMail] = useState(storedEmail);
  const [mails, setMails] = useState([]);
  const [sidebarVisible, setSidebarVisible] = useState(false);

  const showSidebar = () => {
    setSidebarVisible(true);
  };

  const hideSidebar = () => {
    setSidebarVisible(false);
  };
  useEffect(() => {
    if (!selectedMail) {
      generateEmail();
    } else {
      // Only fetch mails if selectedMail is already set
      fetchMail(selectedMail.login, selectedMail.domain);
    }
  }, [selectedMail]);

  const generateEmail = () => {
    axios
      .get('https://www.1secmail.com/api/v1/?action=genRandomMailbox&count=1')
      .then(response => {
        const newEmail = response.data[0];
        const [login, domain] = newEmail.split('@');
        const generatedEmail = { login, domain };
        localStorage.setItem('generatedEmail', JSON.stringify(generatedEmail));
        setSelectedMail(generatedEmail);
        fetchMail(login, domain);
      })
      .catch(error => {
        console.error('Error generating email: ', error);
      });
  };

  const fetchMail = (login, domain) => {
    axios
      .get(`https://www.1secmail.com/api/v1/?action=getMessages&login=${login}&domain=${domain}`)
      .then(response => {
        setMails(response.data);
      })
      .catch(error => {
        console.error('Error fetching mails: ', error);
      });
  };

  const fetchSingleMail = id => {
    const { login, domain } = selectedMail;
    axios
      .get(
        `https://www.1secmail.com/api/v1/?action=readMessage&login=${login}&domain=${domain}&id=${id}`
      )
      .then(response => {
        setSelectedMail(response.data);
      })
      .catch(error => {
        console.error('Error fetching single mail: ', error);
      });
  };

  const refreshMails = () => {
    const { login, domain } = selectedMail;
    fetchMail(login, domain);
  };

  const copyEmail = () => {
    const emailInput = document.createElement('input');
    emailInput.value = `${selectedMail?.login ?? ''}@${selectedMail?.domain ?? ''}`;
    document.body.appendChild(emailInput);
    emailInput.select();
    document.execCommand('copy');
    document.body.removeChild(emailInput);
  };

  return (

    <BrowserRouter>
      <div className="top-section">

          <div className={`toggle-button ${sidebarVisible ? 'visible' : ''}`} onClick={showSidebar}>&#9776;</div>


        <div style={{ display: 'flex' }}>
          <div className="logo">
            <Link to="/"><img src='./logo.png' alt="mail box" height={'auto'} width={'250px'} /></Link>

          </div>
        </div>
        <div className="email-display">
          <input
            type="text"
            value={`${selectedMail?.login ?? ''}@${selectedMail?.domain ?? ''}`}
            readOnly
          />
          <button onClick={copyEmail}>Copy Email</button>

        </div>

        <div className="email-display">
          <button onClick={generateEmail}>Change Email</button>
        </div>
      </div>

      <div className="container">

        <div className={`sidebar ${sidebarVisible ? 'visible' : ''}`}>
          <div className="sidebar-navigation">
          <div className={`toggle-button ${sidebarVisible ? 'visible' : ''}`} style={{color:'#ff9100'}} onClick={hideSidebar}>&#10006;</div>
            <Link to="/">Inbox</Link>
            <Link to="/privatepolicy">Private Policy</Link>
            <Link to="/contact">Contact</Link>
            <Link to="/about">About Us</Link>

            {/* <div style={{ height: '450px', width: '200px', backgroundColor: 'grey' }}>Ads: height:450px,width:200px</div> */}

          </div>
        </div>

        <div className='mailbox'>
          <Routes>
            <Route
              path="/"
              element={
                <Mailbox mails={mails} fetchSingleMail={fetchSingleMail} refreshMails={refreshMails} />
              }
            />
            <Route path="/email/:id" element={<Email email={selectedMail} setSelectedMail={setSelectedMail} />} />
            <Route path="/privatepolicy" element={<PrivacyPolicy />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/about" element={<AboutUs />} />

          </Routes>

          {/* <div style={{ height: '200px', width: '750px', backgroundColor: 'grey' }}>Ads:<br />height:200px,width:750px</div> */}


        </div>
        {/* <div style={{ height: '500px', width: '200px', backgroundColor: 'grey' }}>Ads:<br />height:500px,width:200px</div> */}
      </div>
    </BrowserRouter>
  );
}

export default App;







