import React from 'react';
import '../App.css';

const AboutUs = () => {
  return (
    <div className="about-us-container">
      <h2>About Us:</h2>
      <p>
        At our Temp Mail service, we embark on a relentless pursuit of perfection, considering it not just a lofty goal but an attainable objective. Despite the perceived impossibility of achieving perfection, we believe that continuous striving brings us closer to it. Your suggestions and comments are not mere words on a screen – they are the building blocks of our commitment to enhancing our service.
      </p>
      <p>
        If you're enjoying our anonymous email service, we encourage you to share your positive experiences with others through reviews. Your ideas on collaboration to achieve perfection are highly valued and will play a crucial role in making our service more convenient, user-friendly, and responsive to the needs of those seeking temporary email solutions.
      </p>
      <p>
        Your involvement matters greatly to us. By helping us evolve, we, in turn, promise to provide you with the utmost security in an anonymous inbox and an interface that prioritizes user comfort. Join us on this journey towards excellence!
      </p>
    </div>
  );
};

export default AboutUs;
